<template>
  <div v-loading="loading" class="union-user">
    <div class="filter">
      <div class="filter-item">
        <div class="seach-item">
          选择学校
          <a-select
            v-model="schoolIds"
            show-search
            :filter-option="filterOption"
            allow-clear
            mode="multiple"
            not-found-content="暂无数据"
            placeholder="所有学校（可输入查询）"
            style="width: 250px"
            @change="filterChange"
          >
            <a-select-option
              v-for="(item, index) in schoolList"
              :key="index"
              :value="item.schoolId"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="seach-item">
          科目
          <a-select
            v-model="searchSubjectIds"
            not-found-content="暂无数据"
            mode="multiple"
            allow-clear
            placeholder="所有科目"
            style="width: 220px"
            :filter-option="filterOption"
            @change="filterChange"
          >
            <a-select-option
              v-for="(item, index) in subjectList"
              :key="index"
              :value="item.subjectId"
            >
              {{ item.subjectName }}
            </a-select-option>
          </a-select>
        </div>
        <div class="seach-item">
          学段
          <a-select
            v-model="levelIds"
            allow-clear
            not-found-content="暂无数据"
            placeholder="所有学段"
            style="width: 220px"
            :filter-option="filterOption"
            mode="multiple"
            @change="filterChange"
          >
            <a-select-option
              v-for="(item, index) in levelOptions"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="seach-item">
          分类
          <a-select
            v-model="category"
            style="width: 220px"
            allow-clear
            placeholder="所有分类"
            mode="multiple"
            :filter-option="filterOption"
            @change="filterChange"
          >
            <a-select-option
              v-for="(item, index) in categorys"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="filter-item">
        <a-input
          v-model="search.keyword"
          allow-clear
          autocomplete="new-password"
          style="width: 180px; margin-bottom: 18px"
          placeholder="请输入姓名或用户名"
          @input="filterChange"
        ></a-input>
      </div>
    </div>
    <div class="operate">
      <div class="title">
        用户列表
        <span v-if="selectedRows.length"
          >已选择<span class="selectd">{{ selectedRows.length }}</span
          >条数据</span
        >
      </div>
      <div class="btns">
        <a-button type="primary" @click="add"
          ><a-icon type="plus-circle" />添加</a-button
        >

        <a-dropdown>
          <a-button type="primary">
            <img
              src="@/assets/uExam/icon_import.png"
              style="width: 16px; margin-right: 6px"
              alt=""
            />
            导入
          </a-button>
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item key="2">批量新增</a-menu-item>
            <a-menu-item key="1">批量修改</a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-button
          type="primary"
          :loading="exportLoading"
          @click="exportSchoolUserFunc"
          ><img
            src="@/assets/uExam/icon_export.png"
            style="width: 16px; margin-right: 6px"
            alt=""
          />
          导出</a-button
        >
        <a-button type="primary" @click="batchDelete"
          ><img
            src="@/assets/uExam/icon-del.png"
            style="width: 16px; margin-right: 6px"
            alt=""
          />批量删除</a-button
        >

        <a-button type="danger" @click="clearUser"
          ><img
            src="@/assets/uExam/clear-white.png"
            style="width: 16px; margin-right: 6px"
            alt=""
          />
          清空用户</a-button
        >
      </div>
    </div>
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :row-key="(record) => record.id"
      :data-source="tableData"
      bordered
      :locale="locale"
      :pagination="{
        total: total,
        pageSize: search.size,
        current: search.current,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total}条`,
      }"
      @change="tablePaginationChange"
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="edit(record)">修改</a-button>
        <a-button type="link" style="color: #f57574" @click="del(record)"
          >删除</a-button
        >
      </span>
    </a-table>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :ok-text="'确定'"
      :cancel-text="'取消'"
      :width="
        title == '清空用户'
          ? clearType == 1
            ? '60%'
            : '25% '
          : modalWidth[title]
      "
      :footer="undefined"
      @cancel="handleCancel"
    >
      <template v-if="title === '添加用户' || title === '修改用户'">
        <div class="addForm">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 14 }"
            :colon="false"
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="学校名称"
                  :colon="false"
                  prop="schoolId"
                >
                  <a-select
                    v-model="form.schoolId"
                    allow-clear
                    not-found-content="暂无数据"
                    show-search
                    :filter-option="filterOption"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="(item, index) in schoolList"
                      :key="index"
                      :value="item.schoolId"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="姓名" :colon="false" prop="name">
                  <a-input
                    v-model="form.name"
                    class="nameInput"
                    placeholder="请输入"
                    :max-length="20"
                    :suffix="`${form.name ? form.name.length : 0}/20`"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item
                  label="用户名"
                  :colon="false"
                  prop="username"
                >
                  <a-input
                    v-model="form.username"
                    placeholder="汉字，字母，数字或下划线"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="密码" :colon="false" prop="password">
                  <a-input
                    v-model="form.password"
                    placeholder="数字，字母或者特殊字符"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="学段" :colon="false" prop="level">
                  <a-select
                    v-model="form.level"
                    mode="multiple"
                    allow-clear
                    not-found-content="暂无数据"
                    placeholder="请选择"
                    @change="$forceUpdate()"
                  >
                    <a-select-option
                      v-for="(item, index) in levelOptions"
                      :key="index"
                      :value="item.value"
                      >{{ item.label }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="联系方式" :colon="false" prop="phone">
                  <a-input v-model="form.phone" placeholder="请输入" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="邮箱" :colon="false" prop="mail">
                  <a-input v-model="form.mail" placeholder="请输入" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="分类" :colon="false" prop="type">
                  <a-input v-model="form.type" placeholder="请输入" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="3">
                <div class="label">学科</div>
              </a-col>
            </a-row>

            <a-checkbox-group v-model="checkList" style="width: 100%">
              <a-row>
                <a-col
                  v-for="(item, index) in subjectList"
                  :key="index"
                  :span="6"
                >
                  <a-checkbox
                    style="margin-bottom: 18px"
                    :value="item.subjectId"
                  >
                    {{ item.subjectName }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-form-model>
        </div>
      </template>
      <template v-if="title === '批量新增'">
        <div class="upload">
          <ImportUpload
            :key="uploadKey"
            :file-model="{
              name: '用户导入模板.xls',
              url: 'https://static.wtjy.com/cms/template/%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
            }"
            @change="uploadChange"
          >
            <template slot="tips">
              <div class="tips">
                <div class="tips-title">【提示】</div>
                <div>1、模板中姓名为必填项，最多为20个汉字；</div>
                <div>2、若模板中用户名为空，导入系统后可自动生成用户名；</div>
                <div>
                  3.1、当“选择导入学校”为“所有学校”时，模板中需要填写学校名称，学校名称需要和提供到系统中的一致；
                </div>
                <div>
                  3.2、当“选择导入学校”已指定学校时，模板中则无需填写学校名称；
                </div>
                <div>
                  3.3、若出现“所选择导入学校”与模板中所填写学校名称不一致时，数据则无法导入；
                </div>
                <div>3.4、学段为必填项，若多学段可以用逗号相隔；</div>
                <div>
                  4、若密码只能含有数字，字母或者特殊字符，若未填写，系统默认为123456；
                </div>
                <div>5、模板中分类为非必填项，需要时可填写自定义分类。</div>
              </div>
            </template>
            <template slot="operate">
              <a-select
                v-model="schoolId"
                style="width: 220px"
                placeholder="所有学校"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in schoolList"
                  :key="index"
                  :value="item.schoolId"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </template>
          </ImportUpload>
        </div>
      </template>
      <template v-if="title === '批量修改'">
        <div class="upload">
          <ImportUpload :key="uploadKey" @change="uploadChange">
            <template slot="tips">
              <div class="tips">
                <div class="tips-title">【提示】</div>
                <div>
                  1、可修改除用户名以外的所有信息（姓名、密码、科目、学校、分类）。
                </div>
                <div>
                  2、可先使用导出功能导出需要修改的用户，然后在Excel中修改用户信息，使用批量修改功能导入Excel修改。
                </div>
                <div>3、若要重新上传，请先将原先文档删除后，再行上传。</div>
              </div>
            </template>
          </ImportUpload>
        </div>
      </template>
      <template v-if="title === '批量删除'">
        <div class="batch-text">
          此操作将永久删除
          <span v-if="title === '批量删除'">{{ selectedRows.length }}条</span>
          <span v-else>{{ form.username }}</span>
          的用户信息，是否继续？
        </div>
        <div class="batch-text">
          <el-input
            v-model="notInput"
            style="width: 0; height: 0; overflow: hidden"
            placeholder="请输入内容"
          ></el-input>
          验证登录密码以继续
          <a-input-password
            v-model="password"
            style="width: 180px"
            autocomplete="new-password"
            placeholder="登录密码"
          ></a-input-password>
        </div>
        <div style="text-align: center">此操作不可撤销</div>
      </template>
      <template v-if="title === '清空用户'">
        <div v-if="clearType === 1" class="export-user">
          <div class="tip">
            <a-icon
              type="warning"
              theme="filled"
              style="color: #f57574; margin-right: 4px; font-size: 18px"
            />
            <div>
              <div>注意：</div>
              <div>1、用户被清空后，将不可恢复，请谨慎操作；</div>
              <div>2、该操作需要进行身份验证，身份验证不通过将无法继续；</div>
            </div>
          </div>
          <div class="range">
            <div class="range-item">
              学段：
              <a-select
                v-model="clearForm.level"
                style="width: 220px"
                placeholder="所有学段"
                allow-clear
                :filter-option="filterOption"
                mode="multiple"
              >
                <a-select-option
                  v-for="(item, index) in levelOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
            <div class="range-item">
              学校：
              <a-select
                v-model="clearForm.schoolId"
                style="width: 220px"
                placeholder="所有学校"
                :filter-option="filterOption"
                allow-clear
                mode="multiple"
              >
                <a-select-option
                  v-for="(item, index) in schoolList"
                  :key="index"
                  :value="item.schoolId"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="range-item">
              科目：
              <a-select
                v-model="clearForm.subjectId"
                style="width: 220px"
                allow-clear
                placeholder="所有科目"
                :filter-option="filterOption"
                mode="multiple"
              >
                <a-select-option
                  v-for="(item, index) in subjectList"
                  :key="index"
                  :value="item.subjectId"
                >
                  {{ item.subjectName }}
                </a-select-option>
              </a-select>
            </div>
            <div class="range-item">
              分类：
              <a-select
                v-model="clearForm.category"
                style="width: 220px"
                allow-clear
                placeholder="所有分类"
                :filter-option="filterOption"
                mode="multiple"
              >
                <a-select-option
                  v-for="(item, index) in categorys"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="clearTip">
            <a-icon
              type="info-circle"
              theme="twoTone"
              two-tone-color="#FE8516"
            />
            您将清空
            <span>{{ clearTip }} </span>
            的用户信息是否继续？
          </div>
        </div>
        <div v-if="clearType === 2">
          <div class="batch-text">
            您即将清空
            <span>{{ msgText }}</span>
            的用户信息是否继续？
          </div>
          <el-input
            v-model="notInput"
            style="width: 0; height: 0; overflow: hidden"
            placeholder="请输入内容"
          ></el-input>
          <div class="batch-text">
            验证登录密码以继续
            <a-input-password
              v-model="password"
              autocomplete="new-password"
              placeholder="登录密码"
              style="width: 180px"
            ></a-input-password>
          </div>
          <div style="text-align: center">此操作不可撤销</div>
        </div>
      </template>
      <template slot="footer">
        <div v-if="title === '批量删除'" style="text-align: center">
          <a-button @click="handleCancel">取消</a-button>
          <a-button
            type="danger"
            :loading="confirmLoading"
            @click="clearUserBatch"
            >批量删除</a-button
          >
        </div>
        <div v-if="title === '批量清空'" style="text-align: center">
          <a-button
            @click="
              visible = false;
              password = '';
            "
            >取消</a-button
          >
          <a-button type="danger" :loading="confirmLoading">批量清空</a-button>
        </div>
        <div v-if="title === '导出用户'">
          <a-button @click="visible = false">取消</a-button>
          <a-button type="primary" :loading="confirmLoading"
            >导出指定用户</a-button
          >
        </div>
        <div v-if="title === '添加用户' || title == '修改用户'">
          <a-button
            @click="
              visible = false;
              form = { phone: undefined, mail: undefined, level: [] };
            "
            >取消</a-button
          >
          <a-button
            type="primary"
            :loading="confirmLoading"
            @click="addUserList"
            >提交</a-button
          >
        </div>
        <div v-if="title === '清空用户'" style="text-align: center">
          <a-button
            @click="
              visible = false;
              password = '';
            "
            >取消</a-button
          >
          <a-button
            v-if="clearType == 1"
            :loading="confirmLoading"
            type="danger"
            @click="continueClear"
            >继续清除</a-button
          >
          <a-button
            v-if="clearType == 2"
            :loading="confirmLoading"
            type="danger"
            @click="clearFilterUser"
            >清除用户信息</a-button
          >
        </div>
        <div v-if="title === '批量新增'" style="text-align: center">
          <a-button
            @click="
              visible = false;
              fileList = null;
            "
            >取消</a-button
          >
          <a-button type="primary" :loading="confirmLoading" @click="batchAdd"
            >导入</a-button
          >
        </div>
        <div v-if="title === '批量修改'" style="text-align: center">
          <a-button
            @click="
              visible = false;
              fileList = null;
            "
            >取消</a-button
          >
          <a-button type="primary" :loading="confirmLoading" @click="batchEdit"
            >导入</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {
  getUserList,
  getSchoolNotPageUnionById,
  addUser,
  deleteUser,
  editUser,
  categoryList,
  clearAllUser,
  batchImportSchoolUser,
  batchEditSchoolUser,
  exportSchoolUser,
} from "@/core/api/newOther/union";
import noData from "@/components/noData.vue";
import ImportUpload from "@/components/ImportUpload.vue";
import { getStore } from "@/core/util/store";
import { encryption } from "@/core/util/util";
import { levelOptions } from "@/core/util/constdata";
export default {
  name: "UnionUser",
  components: {
    ImportUpload,
  },
  data() {
    const schoolSubjects = getStore({ name: "schoolSubjectList" });
    const validPassWord = (rule, value, callback) => {
      // let reg = /^(?=.*\d)(?=.*[a-zA-Z]).{5,17}$/;
      const reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (reg.test(value)) {
        callback(new Error("密码不能包含中文"));
      } else {
        callback();
      }
    };
    const validUsername = (rule, value, callback) => {
      if (value) {
        if (!/^[\u4e00-\u9fa5a-zA-Z0-9_]+$/.test(value)) {
          callback(new Error("用户名格式为汉字、字母、数字、下划线组合"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请输入用户名"));
      }
    };
    const validPhone = (rule, value, callback) => {
      let reg = /^1[3|4|5|7|8]\d{9}$/;
      if (!value) {
        callback();
      } else if (!reg.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    const validEmail = (rule, value, callback) => {
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!value) {
        callback();
      } else if (!reg.test(value)) {
        callback(new Error("邮箱格式不正确"));
      } else {
        callback();
      }
    };
    return {
      clearForm: {},
      notInput: "",
      loading: false,
      searchSubjectIds: [],
      uploadKey: Math.random(),
      modalWidth: {
        添加用户: "35%",
        修改用户: "35%",
        批量新增: "35%",
        批量修改: "35%",
        导出用户: "50%",
        清空用户: "50%",
        批量删除: "390px",
      },
      schoolId: undefined,
      search: {
        schoolId: undefined,
        level: undefined,
        subjectId: undefined,
        name: "",
        size: 20,
        current: 1,
      },
      checkList: [],
      schoolIds: [],
      levelIds: [],
      columns: [
        {
          title: "姓名",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "用户名",
          dataIndex: "username",
          align: "center",
        },
        {
          title: "密码",
          dataIndex: "password",
          align: "center",
        },
        {
          title: "学校",
          dataIndex: "schoolName",
          align: "center",
        },
        {
          title: "学段",
          dataIndex: "level",
          align: "center",
          customRender: (text, record) => {
            if (record.levels) {
              let levels = record.levels;
              if (levels.indexOf("，") !== -1) {
                levels = levels.replace("，", ",");
              }
              const arr = levels.split(",");
              let obj = {
                1: "小学",
                2: "初中",
                3: "高中",
                4: "大学",
              };
              let levelArr = arr.map((it) => {
                return obj[it];
              });
              return levelArr.join(",");
            }
          },
        },
        {
          title: "科目",
          dataIndex: "subjectName",
          align: "center",
          width: 150,
          customRender: (text, record) => {
            if (record.subjectIds) {
              let subjectIds = "";
              if (subjectIds.indexOf("，") !== -1) {
                subjectIds = record.subjectIds.replace("，", ",");
              }
              subjectIds = record.subjectIds.split(",");
              let data = subjectIds.map((item) => {
                let subject = schoolSubjects.find((it) => it.subjectId == item);
                if (subject) {
                  return subject.label;
                }
                return "";
              });
              return data.filter((item) => item).join(",");
            }
            return "";
          },
        },

        {
          title: "联系方式",
          dataIndex: "phone",
          align: "center",
        },
        {
          title: "邮箱",
          dataIndex: "mail",
          align: "center",
        },
        {
          title: "分类",
          dataIndex: "type",
          width: 80,
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          width: 180,
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      subjectList: [...schoolSubjects],
      tableData: [],
      total: 0,
      locale: {
        emptyText: <noData message="暂无用户信息，请添加用户信息" />,
      },
      selectedRows: [],
      title: "",
      visible: false,
      confirmLoading: false,
      rules: {
        schoolId: [
          { required: true, message: "请选择学校", trigger: "change" },
        ],
        level: [{ required: true, message: "请选择学段", trigger: "change" }],
        username: [
          { required: true, validator: validUsername, trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        password: [
          { required: true, validator: validPassWord, trigger: "blur" },
        ],
        phone: [{ validator: validPhone, trigger: "blur" }],
        mail: [{ validator: validEmail, trigger: "blur" }],
      },
      form: {
        phone: undefined,
        mail: undefined,
      },
      input: "",
      password: undefined,
      clearType: 1,
      schoolList: [],
      categorys: [],
      category: [],
      fileList: null,
      levelOptions: levelOptions(),
      exportLoading: false,
      msgText: "",
    };
  },
  computed: {
    rowSelection() {
      return {
        onSelect: (record, selected, selectedRows) => {
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectedRows = selectedRows;
        },
      };
    },
    clearTip() {
      let arr = [
        this.clearForm.level && this.clearForm.level.length,
        this.clearForm.schoolId && this.clearForm.schoolId.length,
        this.clearForm.subjectId && this.clearForm.subjectId.length,
        this.clearForm.category && this.clearForm.category,
      ];
      if (
        JSON.stringify(this.clearForm) === "{}" ||
        !arr.some((item) => item)
      ) {
        return "全部";
      } else {
        let levels = [],
          schools = [],
          subjects = [];
        if (this.clearForm.level) {
          levels = this.clearForm.level.map((item) => {
            let level = this.levelOptions.find((it) => it.value == item);
            return level.label;
          });
        }
        if (this.clearForm.schoolId) {
          schools = this.clearForm.schoolId.map((item) => {
            let schools = this.schoolList.find((it) => it.schoolId == item);
            return schools.name;
          });
        }
        if (this.clearForm.subjectId) {
          subjects = this.clearForm.subjectId.map((item) => {
            let subject = this.subjectList.find((it) => it.subjectId == item);
            return subject.subjectName;
          });
        }
        return (
          (levels.length ? levels.join("，") + "；" : "") +
          (schools.length ? schools.join("，") + "；" : "") +
          (subjects.length ? subjects.join("，") + "；" : "") +
          (this.clearForm.category ? this.clearForm.category.join("，") : "")
        );
      }
    },
  },
  mounted() {
    this.getData();
    this.getSchoolListData();
    this.getCategoryList();
  },
  methods: {
    async exportSchoolUserFunc() {
      this.exportLoading = true;
      try {
        const res = await exportSchoolUser({ ...this.search });
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name: res.data.data.name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.exportLoading = false;
      } catch {
        this.exportLoading = false;
      }
    },
    async batchAdd() {
      if (this.fileList) {
        let data = new FormData();
        data.append("file", this.fileList.originFileObj);
        try {
          this.confirmLoading = true;
          const res = await batchImportSchoolUser({
            schoolId: this.schoolId === undefined ? -1 : this.schoolId,
            file: data,
          });
          this.visible = false;
          this.confirmLoading = false;
          this.getData();
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "提交成功",
              showClose: true,
            });
          } else {
            this.$message({
              type: "waring",
              message: res.data.data.msg,
              showClose: true,
            });
          }
        } catch {
          this.confirmLoading = false;
        }
      } else {
        this.$message({
          type: "waring",
          message: "请选择需要导入的文件",
          showClose: true,
        });
      }
    },
    async batchEdit() {
      if (this.fileList) {
        let data = new FormData();
        data.append("file", this.fileList.originFileObj);
        this.confirmLoading = true;
        try {
          const res = await batchEditSchoolUser(data);
          this.visible = false;
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "提交成功",
              showClose: true,
            });
          } else {
            this.$message({
              type: "warning",
              message: res.data.msg,
              showClose: true,
            });
          }
          this.confirmLoading = false;
          this.getData();
        } catch {
          this.confirmLoading = false;
        }
      } else {
        this.$message({
          type: "warning",
          message: "请选择需要导入的文件",
          showClose: true,
        });
      }
    },
    async clearFilterUser() {
      if (!this.password) {
        this.$message({
          type: "warning",
          message: "请输入登录密码",
          showClose: true,
        });
        return false;
      }
      const user = encryption({
        data: {
          randomStr: "blockPuzzle",
          code: "xxx",
          password: this.password,
        },
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      this.confirmLoading = true;
      try {
        await clearAllUser({
          password: user.password,
          ifBatch: true,
          schoolIds:
            this.clearForm.schoolId && this.clearForm.schoolId.length
              ? this.clearForm.schoolId.join(",")
              : null,
          subjectIds:
            this.clearForm.subjectId && this.clearForm.subjectId.length
              ? this.clearForm.subjectId.join(",")
              : null,
          levelIds:
            this.clearForm.level && this.clearForm.level.length
              ? this.clearForm.level.join(",")
              : null,
          types:
            this.clearForm.category && this.clearForm.category.length
              ? this.clearForm.category.join(",")
              : null,
        });
        this.confirmLoading = false;
        this.getData();
        this.visible = false;
        this.$message({
          showClose: true,
          message: "已成功",
          type: "success",
        });
      } catch {
        this.confirmLoading = false;
      }
    },
    async getCategoryList() {
      const res = await categoryList();
      this.categorys = res.data.data.filter((item) => item);
    },
    async getSchoolListData() {
      const res = await getSchoolNotPageUnionById();
      this.schoolList = res.data.data;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterChange() {
      this.search.subjectIds = this.searchSubjectIds.join(",");
      this.search.levelIds = this.levelIds.join(",");
      this.search.current = 1;
      this.search.types = this.category.join(",");
      this.search.schoolIds = this.schoolIds && this.schoolIds.join(",");

      this.getData();
    },
    async getData() {
      this.loading = true;
      const res = await getUserList(this.search).catch(() => {
        this.loading = false;
      });
      this.tableData = res.data.data.records;
      this.total = res.data.data.total;
      this.loading = false;
    },
    continueClear() {
      let levelObj = {
        1: "小学",
        2: "初中",
        3: "高中",
        4: "大学",
      };
      let levelStr = "";
      let schoolStr = "";
      let subjectStr = "";
      let categoryStr = "";
      if (this.clearForm.category) {
        categoryStr = this.clearForm.category.join("、");
      }
      if (this.clearForm.level) {
        let levels = this.clearForm.level.map((item) => levelObj[item]);
        levelStr = levels.join("、");
      }
      if (this.clearForm.schoolId) {
        let schools = this.clearForm.schoolId.map((item) => {
          let school = this.schoolList.find((it) => it.schoolId == item);
          return school.name;
        });
        schoolStr = schools.join("、");
      }
      if (this.clearForm.subjectId) {
        let subjects = this.clearForm.subjectId.map((item) => {
          let subject = this.subjectList.find((it) => it.subjectId == item);
          return subject.subjectName;
        });

        subjectStr = subjects.join("、");
      }
      let arr = [
        this.clearForm.level && this.clearForm.level.length,
        this.clearForm.schoolId && this.clearForm.schoolId.length,
        this.clearForm.subjectId && this.clearForm.subjectId.length,
        this.clearForm.category && this.clearForm.category,
      ];
      if (
        (!this.clearForm.level &&
          !this.clearForm.category &&
          !this.clearForm.schoolId &&
          !this.clearForm.subjectId) ||
        !arr.some((item) => item)
      ) {
        this.msgText = "全部";
      } else {
        this.msgText =
          (levelStr ? levelStr + "学段" : "") +
          (schoolStr ? "，" + schoolStr + "，" : "") +
          (subjectStr ? subjectStr + "科目，" : "") +
          (categoryStr ? categoryStr + "分类" : "");
      }
      this.clearType = 2;
    },

    batchDelete() {
      if (this.selectedRows.length) {
        if (this.selectedRows.length == 1) {
          this.$confirm("此操作将永久删除当前用户, 是否继续?", "操作提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              this.loading = true;

              try {
                await deleteUser({
                  ids: this.selectedRows[0].id,
                  userIds: this.selectedRows[0].userId,
                });
                this.$message({
                  showClose: true,
                  message: "删除成功",
                  type: "success",
                });
                this.loading = false;
                this.form = {};
                this.selectedRows = [];
                this.getData();
              } catch {
                this.loading = false;
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        } else {
          this.visible = true;
          this.title = "批量删除";
        }
      } else {
        this.$message.info("请选择用户");
      }
    },
    async clearUserBatch() {
      this.confirmLoading = true;
      const user = encryption({
        data: {
          randomStr: "blockPuzzle",
          code: "xxx",
          password: this.password,
        },
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      try {
        await deleteUser({
          ids: this.selectedRows.map((item) => item.id).join(","),
          userIds: this.selectedRows.map((item) => item.userId).join(","),
          password: user.password,
          ifBatch: true,
        });
        this.confirmLoading = false;
        this.password = undefined;
        this.visible = false;

        this.$message({
          showClose: true,
          message:
            "已成功！您已成功删除" + this.selectedRows.length + "条用户信息",
          type: "success",
        });
        this.selectedRows = [];
      } catch {
        this.confirmLoading = false;
      }
      this.search.current = 1;
      this.getData();
    },
    async deleteUserSingle() {
      this.confirmLoading = true;
      await deleteUser({
        ids: this.form.id,
        userIds: this.form.userId,
        password: this.password,
      }).catch(() => {
        this.confirmLoading = false;
      });
      this.form = {
        phone: undefined,
        mail: undefined,
        level: [],
      };
      this.confirmLoading = false;
      this.password = undefined;
      this.visible = false;
      this.getData();
    },
    handleMenuClick({ key }) {
      if (key === "2") {
        this.title = "批量新增";
        this.visible = true;
        this.fileList = null;
        this.uploadKey = Math.random();
      } else if (key === "1") {
        this.title = "批量修改";
        this.fileList = null;
        this.visible = true;
        this.uploadKey = Math.random();
      }
    },
    uploadChange({ fileList }) {
      this.fileList = fileList[0];
    },
    tablePaginationChange(val) {
      if (this.search.size !== val.pageSize) {
        this.search.current = 1;
        this.search.size = val.pageSize;
      } else {
        this.search.current = val.current;
      }
      this.getData();
    },
    schoolChange() {
      this.search.current = 1;
      this.getData();
    },
    edit(record) {
      this.form = { ...record };
      this.title = "修改用户";
      this.visible = true;
      let subjectIds = [];
      if (record.subjectIds.indexOf("，") !== -1) {
        subjectIds = record.subjectIds.split("，");
      } else if (record.subjectIds.indexOf(",") !== -1) {
        subjectIds = record.subjectIds.split(",");
      } else if (record.subjectIds) {
        subjectIds = [record.subjectIds];
      }
      if (subjectIds.length) {
        this.checkList = subjectIds.map(Number);
      }
      if (record.levels) {
        this.form.level = [...record.levels.split(",")];
      } else {
        this.form.level = [Number(this.levelOptions[0].value)];
      }
      this.$forceUpdate();
    },
    del(record) {
      this.form = { ...record };
      this.$confirm("此操作将永久删除当前用户, 是否继续?", "操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          try {
            await deleteUser({ ids: record.id, userIds: record.userId });
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success",
            });
            this.loading = false;
            this.form = {};
            this.selectedRows = [];
            this.getData();
          } catch {
            this.loading = false;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleCancel() {
      this.visible = false;
      this.form = {};
      this.checkList = [];
      this.password = "";
    },
    add() {
      this.title = "添加用户";
      this.visible = true;
      if (this.$refs.form) this.$refs.form.clearValidate();
    },
    clearUser() {
      this.title = "清空用户";
      this.visible = true;
      this.clearType = 1;
      this.password = null;
    },
    addUserList() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.form.subjectIds = this.checkList.join(",");
          if (this.form.level && this.form.level.length) {
            this.$set(this.form, "levels", this.form.level.join(","));
          }
          if (this.form.password) {
            const user = encryption({
              data: {
                randomStr: "blockPuzzle",
                code: "xxx",
                password: this.form.password,
              },
              key: "pigxpigxpigxpigx",
              param: ["password"],
            });
            this.form.encoderPassword = user.password;
          }
          if (this.title == "添加用户") {
            await addUser(this.form);
            this.visible = false;
            this.search.current = 1;
            this.getData();
            this.form = { level: [] };
            this.$message.success("提交成功");
            this.checkList = [];
          } else {
            await editUser(this.form);
            this.visible = false;
            this.search.current = 1;
            this.getData();
            this.form = { level: [] };
            this.$message.success("提交成功");
            this.checkList = [];
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.union-user {
  .filter {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .filter-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .seach-item {
        margin-bottom: 18px;
      }
      .seach-item + .seach-item {
        margin-left: 24px;
      }
    }
  }
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 16px;
      color: #0a1119;
      margin-bottom: 18px;
      span {
        font-size: 14px;
        color: #0a1119;
        margin-left: 18px;
        .selectd {
          color: #2474ed;
          margin-left: 0;
        }
      }
    }
    .btns {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .ant-btn {
        margin-bottom: 18px;
      }
      .ant-btn + .ant-btn {
        margin-left: 18px;
      }
    }
  }
}
.hollyShit {
  ::v-deep .ant-form-item-children {
    vertical-align: -webkit-baseline-middle;
  }
}
.upload {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .uploadTips {
    background: #fff5f0;
    border: 1px dashed #ffb692;
    box-sizing: border-box;
    padding: 10px;
    flex: 1;
    color: #343231;
    font-size: 12px;
    margin-left: 18px;
    .footnote {
      color: #e5611f;
      margin-bottom: 18px;
    }
    .text {
      margin-bottom: 18px;
    }
  }
}
.export-user {
  font-size: 14px;
  color: #292626;
  .tip {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 10px 18px;
    background-color: #fff3f3;
    margin-bottom: 18px;
    span {
      color: #ff6c73;
    }
  }
  .title {
    color: #2474ed;
    margin-bottom: 18px;
  }
  .range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &-item {
      flex: 1;
      display: flex;
      margin-bottom: 18px;
      align-items: center;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  .clearTip {
    color: #292726;
    font-size: 14px;
    span {
      color: #f57574;
      font-size: 14px;
    }
  }
}
.addForm {
  ::v-deep .ant-form-item label {
    width: 100%;
    text-align: justify;
    text-align-last: justify;
  }
}
.label {
  color: #000000d9;
  width: 100%;
  text-align: justify;
  text-align-last: justify;
  padding-right: 8px;
  margin-bottom: 24px;
}
.batch-text {
  font-size: 14px;
  margin-bottom: 18px;
  span {
    color: #f57574;
  }
}
::v-deep .nameInput {
  .ant-input {
    padding-right: 60px !important;
  }
}
.ant-btn + .ant-btn {
  margin-left: 8px !important;
}
::v-deep .ant-input {
  padding-right: 8px !important;
}
::v-deep .ant-modal-body {
  line-height: 1 !important;
}
</style>
